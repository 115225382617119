import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Testimonials from "../components/testimonials-list";
import { Button } from "react-bootstrap";
import { FaLinkedin } from "react-icons/fa";

const TestimonialsPage = () => {
	// const [reviews, setReviews] = useState([]);

	// useEffect(() => {
	//   axios
	//     .get(
	//       `https://www.local-marketing-reports.com/external/showcase-reviews/widgets/19d4f28889860f7faaef66602d649c33f833b6bb`
	//     )
	//     .then((res) => {
	//       const reviewData = res.data.results;
	//       setReviews(reviewData);
	//       setReviews(reviewData);
	//       document.getElementById("loading-spinner").style.display = "none";
	//       document.getElementById("no-reviews").style.display = "block";
	//     });
	// });

	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "jason-client-meeting-5.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 90) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Video Testimonials",
				item: {
					url: `${siteUrl}/video-testimonials`,
					id: `${siteUrl}/video-testimonials`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Video Testimonials | Jason Cornes"
				description="Most of us could benefit from another point of view, a guiding hand, or an experienced businessperson to be a ‘sounding board’ and hold us accountable."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/video-testimonials`,
					title: "Video Testimonials | Jason Cornes",
					description:
						"Most of us could benefit from another point of view, a guiding hand, or an experienced businessperson to be a ‘sounding board’ and hold us accountable.",
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Jason Cornes Business Coaching Testimonials",
						},
					],
				}}
			/>
			<Container className="pb-5">
				<Row className="pt-5 text-center">
					<Col className="text-left">
						<h1 className="text-center">
							Business & Executive Coaching Video Testimonials
						</h1>
						<hr className="bg-secondary text-center w-25 " />
						<p className="pt-3">
							I’m pleased and privileged to have helped so many clients become
							the successful business or practice owners they were meant to be.
							Therefore, I thought I’d include some Business & Executive
							Coaching client video testimonials for you to see that I put my
							money where my mouth is, so to speak! Every single one of us is
							different and my coaching will be tailored to your current
							circumstances, needs and requirements.
						</p>
						<p>
							Most of us could benefit from another point of view, a guiding
							hand, or an experienced businessperson to be a ‘sounding board’
							and hold us accountable.
						</p>
						<p>
							Please listen about how I’ve delivered just those very things. You
							might also like to visit my{" "}
							<Link to="/written-testimonials">Written Testimonials</Link> page.
						</p>
					</Col>
				</Row>
			</Container>
			<section className="bg-grey py-5 py-lg-7">
				<Container>
					<Row className="g-5">
						<Col md={6} lg={4}>
							{" "}
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/NWsrLdIIQ0I?si=1LitthZlHGegLDbF"
									title="Inga Kopala. Amberth Kitchens, Bathrooms & Interiors."
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Inga Kopala. Amberth Kitchens, Bathrooms & Interiors.
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/inga-kopala-2a82012/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							{" "}
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/moZjfP9BxQE?si=Y3qLZskQe9G7ReaK"
									title="Mark Wiffen. Marlin Design Architectural Services Ltd"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Mark Wiffen. Marlin Design Architectural Services Ltd
							</h6>
						</Col>{" "}
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/pzRLZ_GvIN8?si=SVJ55h01gkr5W2H-"
									title="Dr Alice Scott. Essex Private Doctors"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Dr Alice Scott. Essex Private Doctors
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/dr-alice-scott-45b268118/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/1epKcqGWK4o?si=ZZzfZDEDo7zP7HEf"
									title="Elizabeth Keates Film and Photography"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Elizabeth Keates Film and Photography
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/elizabeth-keates-film-photography/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/r4bKsNpKzqE?si=kNDbIiUljA7iwd6R"
									title="Matt Cranfield. Cranfield IT Solutions Ltd"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Matt Cranfield. Cranfield IT Solutions Ltd
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/matt-cranfield-itsupport-sussex-profile/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/3YNLOv1N2BE?si=9uWNq2j7q8OnFSEi"
									title="Claire Freeman. Consultant Surgeon"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Claire Freeman. Consultant Surgeon
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/claire-freeman-frcpods-consultant/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/lHA14JRHFIA?si=fuGs8dcaL0m1FKRJ"
									title="Mike Sherwood. FreshOnline Marketing Ltd"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Mike Sherwood. FreshOnline Marketing Ltd
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/mikesherwood147/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/7F0Rj6Vtos8?si=onqopiVv9S9Y3Fry"
									title="Carol Seaborne. The Back Care Clinic Ltd"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Carol Seaborne. The Back Care Clinic Ltd
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/carol-seaborne-813a09199/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/FeNs8qa0C4Q?si=0smseW5y4HwrXriy"
									title="Greg Stevenson. Pinpoint Studio Ltd"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Greg Stevenson. Pinpoint Studio Ltd
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/greg-stevenson-b8305736/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/IUeAYsPxxjc?si=6iDVOH6sncv9_UZ8"
									title="Andrew Fenton. Chartered Tax Advisor"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Andrew Fenton. Chartered Tax Advisor
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/andrew-fenton-25b54414/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/sp2-RfrN7jU?si=PNUiyjQg1ri7lzsW"
									title="Nick Bryant. Brilliant Businesses UK Ltd"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Nick Bryant. Brilliant Businesses UK Ltd
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/nick-bryant-873a6911/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/yPngfWf9Pcg?si=-GLa9o6g0mDz0pZt"
									title="Robbie Mould. RJM Digital Ltd"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Robbie Mould. RJM Digital Ltd
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/robertmould/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/obUlkJ1DiRI?si=0GADptTu8hte7Z2I"
									title="Jess Cox. Jessica Cox Design Ltd"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Jess Cox. Jessica Cox Design Ltd
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/jessica-cox-63358948/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
						<Col md={6} lg={4}>
							<div className="iframe-container-new mb-4">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/UYc6P9U5lVA?si=Lk1fMr59u1s6VTCB"
									title="Frances Thapen. School Development Ltd"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<h6 className="text-tertiary text-center ">
								Frances Thapen. School Development Ltd
							</h6>
							<p className="text-center pl-auto pr-auto ">
								<a
									rel="noreferrer"
									href="https://www.linkedin.com/in/francesthapen/"
									target="_blank"
									className={`linkedin-icon `}
								>
									<FaLinkedin />
								</a>
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Ready to work with me?</h3>
							<p>
								Contact me today to take the first step to finding more business
								and taking the fast lane to more profits!
							</p>
						</Col>
					</Row>

					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default TestimonialsPage;
